import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess, updateAuthTokens } from '../store/auth/sessionSlice'
import {jwtDecode} from 'jwt-decode';

const unauthorizedCode = [401]

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
})

// BaseService.interceptors.request.use(
//     (config) => {
//         const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
//         const persistData = deepParseJson(rawPersistData)

//         let accessToken = persistData.auth.session.token

//         if (!accessToken) {
//             const { auth } = store.getState()
//             accessToken = auth.session.token
//         }

//         if (accessToken) {
//             config.headers[
//                 REQUEST_HEADER_AUTH_KEY
//             ] = `${TOKEN_TYPE}${accessToken}`
//         }


//         return config
//     },
//     (error) => {
//         return Promise.reject(error)
//     }
// )


// BaseService.interceptors.request.use(
//     async (config) => {
//         const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
//         const persistData = deepParseJson(rawPersistData);

//         let accessToken = persistData?.auth?.session?.userLoginData?.stsTokenManager?.accessToken;
//         let refreshToken = persistData?.auth?.session?.userLoginData?.stsTokenManager?.refreshToken;
  

//         // let accessToken = persistData.auth.session.userLoginData.stsTokenManager.accessToken;
//         // let refreshToken = persistData.auth.session.userLoginData.stsTokenManager.refreshToken;

//         if (!accessToken) {
//             const { auth } = store.getState();
//             accessToken = auth?.session?.userLoginData?.stsTokenManager?.accessToken;
//             refreshToken = auth?.session?.userLoginData?.stsTokenManager?.refreshToken;
//           }

//         if (accessToken) {
//             // Check if the token is expired
//             const isTokenExpired = checkTokenExpiry(accessToken);
//             console.log("Is Token Expired===="+isTokenExpired)
//             console.log("Is Token Expired===refresh="+refreshToken)
//             if (isTokenExpired && refreshToken) {
//                 try {
//                     // Refresh the token
//                     const newTokens = await refreshAuthToken(refreshToken);
//                     accessToken = newTokens.accessToken;
//                     refreshToken = newTokens.refreshToken;

//                     // Update the tokens in local storage and Redux store
//                     persistData.auth.session.userLoginData.stsTokenManager.accessToken = accessToken;
//                     persistData.auth.session.userLoginData.stsTokenManager.refreshToken = refreshToken;
//                     localStorage.setItem(PERSIST_STORE_NAME, JSON.stringify(persistData));
//                     store.dispatch(updateAuthTokens(newTokens));
//                 } catch (error) {
//                     console.error('Error refreshing token:', error);
//                     return Promise.reject(error);
//                 }
//             }

//            // config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
//         }

//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// Function to check if the token is expired
function checkTokenExpiry(token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
}

// Function to refresh the auth token
async function refreshAuthToken(refreshToken) {
    const response = await axios.post(`${appConfig.apiPrefix}/refreshAuthToken`, {
        refreshToken,
    });
    return response.data;
}




BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }

        return Promise.reject(error)
    }
)

export default BaseService
