import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/sign-in',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiGetAllBooking(data) {
    return ApiService.fetchData({
      //  url: '/bookings',
     //  url: '/getAllBooking',
       url:'/getAllBookingUser',
        
        method: 'post',
        data,
        
    })
}

export async function apiUpdateBookingStatus(data) {
    return ApiService.fetchData({
        url: '/updateBookingStatus',
        method: 'post',
        data,
        
    })
}

export async function apiUpdatePriceQuote(data) {
    return ApiService.fetchData({
        url: '/updatePriceQuote',
        method: 'post',
        data,
        
    })
}

export async function apiGetProfileUser(uid) {
    return ApiService.fetchData({
        url: `/getUserProfile?uid=${uid}`,
        method: 'get',
    
        
    })
}

export async function apiGetUserDataAfterLogin(uid) {
    return ApiService.fetchData({
        url: `/getUserProfileOfAuthUser?uid=${uid}`,
        method: 'get',
    
        
    })
}




export async function apiSignInWithPhone(data) {
    return ApiService.fetchData({
        url: '/sendVerificationCode.',
        method: 'post',
        data,
        
    })
}


export async function apiUpdateUserProfileClient(data) {
    return ApiService.fetchData({
        url: '/updateUserProfile',
        method: 'post',
        data,
        
    })
}

export async function apiUpdateDriveDetails(data) {
    return ApiService.fetchData({
        url: '/updateDriveDetails',
        method: 'post',
        data,
        
    })
}

export async function addNewClientApi(data) {
    return ApiService.fetchData({
        url: '/createUserWithPhoneNumber',
        method: 'post',
        data,
        
    })
}


export async function apiAddBookingAndUpdateUser(data) {
    return ApiService.fetchData({
        url: '/addBookingAndUpdateUser',
        method: 'post',
        data,
        
    })
}

export async function apiUpdateUserFirstTime(data) {
    return ApiService.fetchData({
        url: '/updateUserProfileFirstTime',
        method: 'post',
        data,
        
    })
}

export async function apiGetBookingsAdmin(data) {
    return ApiService.fetchData({
        url: '/getBookingAdmin',
        method: 'post',
        data
        
        
    })
}

export async function apiGeAllUsersAdmin(data) {
    return ApiService.fetchData({
    url: '/getAllUsers',
        method: 'post',
        data
        
        
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
