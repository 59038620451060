import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { collection, doc, updateDoc, getDoc, setDoc } from "firebase/firestore";
import { app } from 'configs/firebaseConfig';
import deepParseJson from 'utils/deepParseJson';
import store from 'store';
import { addDoc, getFirestore, query, where, getDocs, arrayUnion } from 'firebase/firestore';
import { setAllUsers, setBookingData, setBookingDataFromFirebase, setProfileData } from 'store/bookings/manageTripSlice';
import { setUserId } from 'firebase/analytics';
import { apiGetUserDataAfterLogin, apiSignInWithPhone, apiUpdateUserFirstTime, apiUpdateUserProfileClient } from 'services/AuthService';
import appConfig from 'configs/app.config';
import axios from 'axios';
import { USER } from 'constants/roles.constant';
import { act } from 'react';
import { first, last } from 'lodash';
import { Email } from '@material-ui/icons';

export const initialState = {

  uid: '',
  authority: USER,
  userDocId: '',
  profileLoad: false,
  loadingMain: false,
  firstName: '',
  lastName: '',
  companyName: '',
  mobileNumber: '',
  email: '',

}


export const fetchUserDetail = createAsyncThunk('auth/fetchUserDetail', async (data, { dispatch }) => {

  // console.log("response.databeforeeeeeeee",data.uid)

  try {
    const firestore = getFirestore(app);
    // Reference to the user document
    const userDocRef = doc(firestore, 'users', data.uid);

    // Fetch the user document
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      // User exists, return user data
      const userData = userDoc.data();
      if (userData) {
        dispatch(setBookingDataFromFirebase(userData.bookingData))


      const userResponse = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        uid: userData.uid,
        userDocId: userDoc.id,
        companyName: userData.companyName,
        role: userData.role,
        email: userData.email,
        mobileNumber: userData.mobileNumber,
      };
        dispatch(updateUser(userData.profile))
        dispatch(updateRole(userData.profile.role ? userData.profile.role : USER))

      }

      return;
    } else {
      // User does not exist, add a new user entry
      const stateToPersist = deepParseJson(JSON.stringify(data));

      await setDoc(userDocRef, stateToPersist);
      dispatch(setBookingData(stateToPersist))
      return;

      // return data;

      // Return the new user data
      // return newUser;
    }
    // setSuggestions(response.data.predictions || []);
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw new Error('Failed to fetch user details');
  }



})

export const signInPhone = createAsyncThunk('auth/signInPhone', async (data, { dispatch }) => {

  console.log("response.databeforeeeeeeee bebebjgwegrjgjwegrjejh", data)

  // Create an instance of Axios
  const apiClient = axios.create({
    baseURL: appConfig.apiPrefix, // Replace with your API base URL
    headers: {
      'Content-Type': 'application/json',
    }

  });


  const response = await apiClient.post('/sendVerificationCode', data); // Replace with your API endpoint


  // const response = await apiSignInWithPhone(data);

  console.log("response.databeforeeeeeeee", response.data)

  return response.data;

})

export const updateProfileFirstTime = createAsyncThunk('auth/updateProfileFirstTime', async (data, { dispatch }) => {


  const response = await apiUpdateUserFirstTime(data);

  //console.log("response.databeforeeeeeeee", response.data)

  return response.data;


})

export const getUserDataAfterLogin = createAsyncThunk('auth/getUserDataAfterLogin', async (data, { dispatch }) => {


  const response = await apiGetUserDataAfterLogin(data);

  //console.log("response.databeforeeeeeeee", response.data)

  return response.data;


})

export const updateUserProfileClient = createAsyncThunk('auth/updateUserProfileClient', async (data, { dispatch }) => {


  const response = await apiUpdateUserProfileClient(data);

  //console.log("response.databeforeeeeeeee", response.data)

  return response.data;


})

export const getUsersFromDb = createAsyncThunk('auth/getUsersFromDb', async (data, { dispatch }) => {

  //  console.log("response.databeforeeeeeeee",data.uid)

  try {
    const firestore = getFirestore(app);
    // Reference to the user document
    const userDocRef = doc(firestore, 'users', store.getState().auth.user.uid);

    // Fetch the user document
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      // User exists, return user data
      const userData = userDoc.data();
      if (userData.bookingData || userData.profile) {
        dispatch(setBookingDataFromFirebase(userData.bookingData))
        dispatch(setProfileData(userData.profile))
      }

      return;
    } else {
      // User does not exist, add a new user entry
      //    const stateToPersist = deepParseJson(JSON.stringify(data));
      throw new Error('No user Found');
      //    await setDoc(userDocRef, stateToPersist);
      //    dispatch(setBookingData(stateToPersist))
      //    return;

      // return data;

      // Return the new user data
      // return newUser;
    }
    // setSuggestions(response.data.predictions || []);
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw new Error('Failed to fetch user details');
  }



})




export const getAllUsersFromDbAdmin = createAsyncThunk(
  'auth/getAllUsersFromDbAdmin',
  async (data, { dispatch, getState }) => {
    try {
      const firestore = getFirestore(app);

      // Get a collection reference for all users (assuming a 'users' collection)
      const usersCollectionRef = collection(firestore, 'users');

      // Fetch all documents from the users collection
      const usersSnapshot = await getDocs(usersCollectionRef);

      // Extract user data from each document, excluding empty objects
      const usersData = usersSnapshot.docs.map((doc) => {
        const userData = doc.data();
        if (Object.keys(userData).length > 0) {
          return {
            ...userData,
          };
        }
        return null;
      }).filter(user => user !== null);
      // Dispatch the setAllUsers action with the fetched user data
      dispatch(setAllUsers(usersData));

      return usersData; // Optionally return the fetched user data
    } catch (error) {
      console.error('Error fetching all users:', error);
      throw new Error('Failed to fetch all users');
    }
  }
);



export const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (_, action) => action.payload,
    userLoggedOut: () => initialState,
    updateUserBooking: (state, action) => {

      state.bookings = arrayUnion(state.bookings, action.payload)

    },
    updateUserId: (state, action) => {

      state.uid = action.payload

    },
    updateRole: (state, action) => {

      state.authority = action.payload

    },
    updateUser: (state, action) => {

      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.companyName = action.payload.companyName
      state.mobileNumber = action.payload.mobileNumber
      state.email = action.payload.email

    }


  },
  extraReducers: (builder) => {
    //  console.log("Inside sendPromptChatGPT"+builder);
    builder
      .addCase(fetchUserDetail.pending, (state, action) => {
        // state.mapsPredictions = action.payload;
        state.profileLoad = true;
      })
      .addCase(fetchUserDetail.rejected, (state, action) => {
        state.loadingMain = false
      })
      .addCase(fetchUserDetail.fulfilled, (state, action) => {
        state.loadingMain = false
      })
      .addCase(updateProfileFirstTime.fulfilled, (state, action) => {
        state.userDocId = action.payload.userId
      })
      .addCase(updateUserProfileClient.pending, (state, action) => {
        state.profileLoad = true;
      })
      .addCase(updateUserProfileClient.fulfilled, (state, action) => {
        state.profileLoad = false
        
      })
      .addCase(getUserDataAfterLogin.fulfilled, (state, action) => {
        state.firstName = action.payload.firstName
        state.lastName = action.payload.lastName
        state.companyName = action.payload.companyName
        state.mobileNumber = action.payload.mobileNumber
        state.email = action.payload.email
        state.authority = action.payload.role
        state.uid = action.payload.uid
        state.userDocId = action.payload.userDocId
        
      })






  }
})

export const { setUser, updateUserId, updateRole, updateUser } = userSlice.actions

export default userSlice.reducer
