import { combineReducers } from '@reduxjs/toolkit'
import maps from './mapSlice'
import manageTrip from './manageTripSlice'

const reducer = combineReducers({
    maps,
    manageTrip
})

export default reducer
